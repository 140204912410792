import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/app/layout.client.tsx");
;
import(/* webpackMode: "eager" */ "/workspace/node_modules/.pnpm/next@14.2.16_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/font/google/target.css?{\"path\":\"app/fonts.ts\",\"import\":\"Playfair_Display\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-playfair\"}],\"variableName\":\"playfair\"}");
;
import(/* webpackMode: "eager" */ "/workspace/node_modules/.pnpm/next@14.2.16_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/font/google/target.css?{\"path\":\"app/fonts.ts\",\"import\":\"Raleway\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-raleway\"}],\"variableName\":\"raleway\"}");
;
import(/* webpackMode: "eager" */ "/workspace/node_modules/.pnpm/next@14.2.16_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/font/google/target.css?{\"path\":\"app/fonts.ts\",\"import\":\"Fuzzy_Bubbles\",\"arguments\":[{\"weight\":[\"400\",\"700\"],\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-fuzzy-bubbles\"}],\"variableName\":\"fuzzyBubbles\"}");
;
import(/* webpackMode: "eager" */ "/workspace/node_modules/.pnpm/next@14.2.16_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/font/google/target.css?{\"path\":\"app/fonts.ts\",\"import\":\"Roboto\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-roboto\",\"weight\":\"400\"}],\"variableName\":\"roboto\"}");
;
import(/* webpackMode: "eager" */ "/workspace/app/globals.css");
