import { firestoreDb } from './firebaseConfig';
import { doc, getDoc, setDoc, arrayUnion, updateDoc } from 'firebase/firestore';

export interface FirebaseUserData {
  createdAt: string; // e.g., ISO timestamp
  email: string;
  feedback: string[];
  loraIdempotencyKey: string;
  loraPaymentIntentId: string;
  loraPaymentStatus: string;
  loraPaymentStatusReason: string | null;
  loraPaymentTimestampUpdate: number;
  loraTriggerWord: string;
  loraUrl: string;
  loraUrlRequestId: string;
  numberOfImagesUploaded: number;
  stripeCustomerId: string;
}

export const getFirebaseUserData = async (userEmail: string): Promise<FirebaseUserData | undefined | null> => {
  const docRef = doc(firestoreDb, 'users', userEmail);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    const data = docSnap.data() as FirebaseUserData;
    return data;
  }
}

export const updateFeedback = async (userEmail: string, feedback: string) => {
  const docRef = doc(firestoreDb, 'users', userEmail);
  const data = await getFirebaseUserData(userEmail);

  if (data && 'feedback' in data) {
    await updateDoc(docRef, {
      feedback: arrayUnion(feedback)
    });
  } else {
    await setDoc(docRef, { feedback: [feedback] }, { merge: true });
  }
}