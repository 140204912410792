'use client';

import { FeedbackModal } from '@/components/valentino/FeedbackModal';
import React, { createContext, useContext, useState } from 'react';

interface FeedbackContextType {
  isModalOpen: boolean;
  setIsModalOpen: (open: boolean) => void;
}

const FeedbackContext = createContext<FeedbackContextType | undefined>(undefined);

export const FeedbackProvider = ({ children }: { children: React.ReactNode }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <FeedbackContext.Provider value={{ isModalOpen, setIsModalOpen }}>
      {children}
      <FeedbackModal 
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </FeedbackContext.Provider>
  );
};


export const useFeedback = () => {
  const context = useContext(FeedbackContext);
  if (context === undefined) {
    throw new Error('useFeedback must be used within a FeedbackProvider');
  }
  return context;
}; 