'use client';

import { LoadingProvider } from '@/contexts/LoadingContext';
import { Toaster } from 'react-hot-toast';
import { FeedbackProvider } from '@/contexts/FeedbackContext';
import DeployedVersionLabel from '@/components/shared/DeployedVersionLabel';
import ReCaptchaInitializer from '@/components/shared/ReCaptchaInitializer';



export default function ClientLayout({
  children,
}: {
  children: React.ReactNode
}) {
  return (
    <LoadingProvider>
      <FeedbackProvider>
        <ReCaptchaInitializer />
          <DeployedVersionLabel />
          {children}
          <Toaster position="top-center" />
      </FeedbackProvider>
    </LoadingProvider>
  );
}