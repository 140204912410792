import { auth } from '@/scripts/firebaseConfig';
import { updateFeedback } from '@/scripts/firebaseStatusChecks';
import React, { useState } from 'react';

interface FeedbackModalProps {
    isOpen: boolean;
    onClose: () => void;
}

export function FeedbackModal({ isOpen, onClose }: FeedbackModalProps) {
    const [feedback, setFeedback] = useState('');
    const [showThankYou, setShowThankYou] = useState(false);

    const handleSubmit = async () => {
        setShowThankYou(true);

        const userEmail = auth.currentUser?.displayName;
        if (!userEmail) {
            return;
        }
        if (feedback.trim() === "") {
            updateFeedback(userEmail, "Feedback was empty"); // still update feedback so we know they tried to submit
            setShowThankYou(false);
            onClose();
            return;
        }
        await updateFeedback(userEmail, feedback).then(() => {
            setFeedback('');
        });

        setTimeout(() => {
            setShowThankYou(false);
            onClose();
        }, 2000);
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white rounded-lg p-6 w-full max-w-md relative">
                <button
                    onClick={onClose}
                    className="absolute right-4 top-4"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="#888" viewBox="0 0 24 24" stroke="#888">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </button>

                {showThankYou ? (
                    <div className="text-center py-8">
                        <p className="text-lg font-medium text-gray-800">Thank you for your comments!</p>
                    </div>
                ) : (
                    <>
                        <h2 className="text-xl font-semibold mb-4">Send us your feedback</h2>
                        <p className="text-gray-600 mb-4">
                            We value your input to improve our services.
                        </p>
                        <textarea
                            value={feedback}
                            onChange={(e) => setFeedback(e.target.value)}
                            className="w-full h-32 p-3 border border-gray-300 rounded-lg mb-4"
                            placeholder="Write your feedback here..."
                        />
                        <button
                            onClick={handleSubmit}
                            className="w-full bg-black text-white py-2 rounded-lg hover:bg-gray-800"
                        >
                            Send Feedback
                        </button>
                    </>
                )}
            </div>
        </div>
    );
} 