import { Analytics, getAnalytics } from "firebase/analytics";
import { initializeApp } from 'firebase/app';
import { Auth, browserLocalPersistence, getAuth, signOut } from 'firebase/auth';
import { Database, getDatabase } from 'firebase/database';
import { Firestore, getFirestore } from 'firebase/firestore';
import { getFunctions, httpsCallable } from "firebase/functions";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDFH_T1cDMbhR7SXNYZkTuVBbpvv99N9_g",
  // authDomain: "clara-mobile-web-a6a1e.firebaseapp.com",
  authDomain: "styledbyclara.ai",
  databaseURL: "https://clara-mobile-web-a6a1e-default-rtdb.firebaseio.com",
  projectId: "clara-mobile-web-a6a1e",
  storageBucket: "clara-mobile-web-a6a1e.firebasestorage.app",
  messagingSenderId: "1040385629865",
  appId: "1:1040385629865:web:4b12f042b7ba580f2713b8",
  measurementId: "G-NN0Y923KRE"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth: Auth = getAuth(app);
auth.setPersistence(browserLocalPersistence);
export const firestoreDb: Firestore = getFirestore(app);
export const realtimeDb: Database = getDatabase(app, firebaseConfig.databaseURL);

// get access to deployed cloud function (recaptcha)
export const functions = getFunctions(app);
export const storage = getStorage(app);

// Conditionally initialize Analytics (only if `window` is available)
export let analytics: Analytics | null = null;
if (typeof window !== 'undefined') {
  analytics = getAnalytics(app);
}

export const processLoraPayment = httpsCallable(functions, 'processLoraPayment');
export const manageFirestoreUsers = httpsCallable(functions, 'manageFirestoreUsers');
export const generateLoraUrl = httpsCallable(functions, 'generateLoraUrl');
export const getLoraTrainingResult = httpsCallable(functions, 'getLoraTrainingResult');
export const getLoraTrainingStatus = httpsCallable(functions, 'getLoraTrainingStatus');
export const generateClothingImage = httpsCallable(functions, 'generateClothingImage');

// Example usage type for processLoraPayment
interface LoraPaymentData {
  userUid: string;
  amount: number;
  currency: string;
}

// Optional: Add type safety wrapper around processLoraPayment
export const initiateLoraPayment = async (paymentData: LoraPaymentData) => {
  try {
    const result = await processLoraPayment(paymentData);
    return result.data as {
      clientSecret: string;
      paymentIntentId: string;
    };
  } catch (error) {
    console.error('Payment processing failed:', error);
    throw error;
  }
};
